import React from 'react'
import Layout from '../../layouts/MainLayout'
import {Link} from 'gatsby'



import { graphql } from 'gatsby';
import { I18n } from 'react-i18next';
import { withI18next } from 'gatsby-plugin-i18next';
import { getContentTranslation } from '../../helpers';



import frt65 from '../../assets/images/produkty/frt65.jpg'
import frt55 from '../../assets/images/produkty/frt55.jpg'
import frt45 from '../../assets/images/produkty/frt45.jpg'
import frt40 from '../../assets/images/produkty/frt40.jpg'

const categories = [
    {   
        category: '65',
        image: frt65,
},
    {category: '55',
image: frt55,
},
    {category: '45',
image: frt45,
},
    {category: '40',
image: frt40,
}
]

const getLink = cat => `produkty/frt-${cat}`

const Produkty = () => <I18n>
    {(t,{lng}) => (<Layout>
        <div id="main" className="alt">
            <section id="one">
                <div className="inner">
                    <header className="major">
                        <h1>Produkty</h1>
                    </header>

                    {

                        // <span className="image main"><img src={pic11} alt="" /></span>
                    }
                   <p>Produkty sú rozdelené podľa veľkosti priemeru piesta tlmiča a použitia v rôznych motoristických disciplínach. Tlmiče FRT sú jedno plášťové plyno-kvapalinové s možnosťami viac stupňovej regulácie. </p>

                   <div className="grid-wrapper">
                        {
                            categories.map(({category, image}, i) => <div className="col-6"><Link key={i} to={getLink(category)}>
                                <img src={image} alt="" className="responsive"/>
                            </Link></div>)       
                        }
                   </div>
                		
				   <h2>Produkty FRT - všeobecná charakteristika</h2>
                    <p>
                    Produkty  sú rozdelené podľa veľkosti priemeru piesta tlmiča a použitia v rôznych motoristických disciplínach. 
                    Tlmiče FRT sú jedno plášťové plyno-kvapalinové s možnosťami viac stupňovej regulácie. Vonkajšími nastavovacími prvkami na externom zásobníku oleja nastavujeme <a href="/inc/ajax.htm#LS" class="tech" onclick="return hs.htmlExpand(this, { objectType: 'ajax'} )"><img src="gui/ico/ls.jpg" /></a>, <a href="/inc/ajax.htm#HS" class="tech" onclick="return hs.htmlExpand(this, { objectType: 'ajax'} )"><img src="gui/ico/hs.jpg" /></a>. 
                    <br/>
                    Nastavovacím kolieskom alebo skrutkou umiestnenou v oku piestnice nastavujeme <a href="/inc/ajax.htm#R" class="tech" onclick="return hs.htmlExpand(this, { objectType: 'ajax'} )"><img src="gui/ico/r.jpg" /></a>. 
                    Tieto nastavovacie možnosti nám umožňujú rýchlo a komfortne zmeniť hodnotu útlmov tlmiča podľa zmeny charakteru  trate  a počasia (za sucha, mokra...)
                    <br/>
                    Ďalšie nastavenia tlmiča sa vykonávajú vo vnútri  zmenou základnej charakteristiky, veľkosti <a href="/inc/ajax.htm#HDB" class="tech" onclick="return hs.htmlExpand(this, { objectType: 'ajax'} )"><img src="gui/ico/hdb.jpg" /></a>, <a href="/inc/ajax.htm#HRB" class="tech" onclick="return hs.htmlExpand(this, { objectType: 'ajax'} )"><img src="gui/ico/hrb.jpg" /></a> a citlivosti systému <a href="/inc/ajax.htm#ARS" class="tech " onclick="return hs.htmlExpand(this, { objectType: 'ajax'} )"><img src="gui/ico/ars.jpg" /></a>.
                    <br/>
                    Produkty FRT od začiatku vývoja  sú optimalizované pre extrémne  požiadavky motoristického športu:</p>

                    <ul>
                            <li><b>Dobrá stabilita a trakčné vlastnosti vozidla</b> – dosahujeme správne navrhnutým hydraulickým systémom bez kavitácie a možnosti zavzdušňovania.</li>
                            <li><b>Nízke trenie</b> - klzne skupiny používajú materiály s nízkym koeficientom trenia PTFE, VITON, DLC, mazacie tuky s  nano technológiou, ktorá pevne viaže s kovovými materiálmi.</li>
                            <li><b>Vysoká tepelná zaťažiteľnosť</b> - použitie olejov s dobrou tepelnou viskóznou stabilitou.  Regulačnými prvkami reagujúcimi na zmenu teploty a viskozity oleja. Výrobnými toleranciami použitých súčiastok.</li>
                            <li><b>Nízka hmotnosť</b> - dosahujeme správnou konštrukciou tlmiča, pevnostnými výpočtami, vysokopevnými konštrukčnými materiálmi a dlhoročnými skúsenosťami. Pri konštrukcii sa používajú CrMoV ocele s pevnosťou 950-1100 MPa, Al-zliatiny EN7075 T6 s pevnosťou 500-540 MPa.</li>
                            <li><b>Korozivzdorná odolnosť</b> - použitie povrchových úprav elektrolytické zinkovanie s chromatovaním, niklovanie, tvrdo chrómové klzné vrstvy, eloxovanie.</li>
                            <li><b>Ľahká údržba a servis</b> - dosahujeme správnou konštrukciou tlmiča, montážnymi prípravkami a odbornými znalosťami servisných mechanikov.</li>
                    </ul>
                    <p>
                            Popri vyššie vymenovaných vlastnostiach taktiež považujeme za dôležitý priaznivý pomer cena - kvalita.
                    </p>
                   </div>
            </section>
        </div>

    </Layout>
)}
</I18n>

export default withI18next()(Produkty)

export const query = graphql`
query($lng: String!) {
  locales: allLocale(filter: { lng: { eq: $lng }, ns: { eq: "messages" } }) {
    ...TranslationFragment
  }
}
`